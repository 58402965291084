<script>
import Layout from "../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapGetters } from "vuex";
import $ from "jquery";
import datetime from "vuejs-datetimepicker";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Health Packs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, datetime },
  data() {
    return {
      activeTab: 1,
      loading: false,
      tableData: [],
      clientpack: [],
      title: "",
      items: [
        {
          text: "Oonpay",
          href: "/"
        },

        {
          text: "Health Packs",
          active: true
        }
      ],
      searchterm: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 2,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterclient: null,
      filterOn: [],
      sortBy: "fullName",
      sortDesc: false,
      fields: [
        { key: "fullName", sortable: true },
        { key: "superCategory", sortable: true },
        { key: "category", sortable: true },
        { key: "subCategory", sortable: true },
        { key: "deliveryMode", sortable: true },
        { key: "isActive", sortable: true },
        { key: "cost" },
        { key: "Action" }
      ],
      clientfields: [
        { key: "beneficiaryFullName", sortable: true },
        { key: "beneficiaryPhoneNumber", sortable: true },
        { key: "packageFullName", sortable: true },
        { key: "packageSubCategory", sortable: true },
        { key: "packageSuperCategory", sortable: true },
        { key: "packageQuantity", sortable: true },
        { key: "packageState", label: "Package Status" }, //packageStatus
        { key: "packageQuantityUnit" },
        { key: "Action" }
      ],
      loadingAction: false,
      fullName: "",
      description: "",
      SuperCategoryId: "",
      categoryId: "",
      EffectiveStartDate: "",
      EffectiveEndDate: "",
      SubCategoryId: "",
      DeliveryModeId: "",
      PackageQuantity: "",
      PackageQuantityUnitId: "",
      ExpirationQuantity: "",
      ExpirationQuantityUnitId: "",
      Cost: "",
      IsActive: false,
      supercategory: [],
      result: {},

      superCatId: "",
      subCatId: "",
      catId: "",
      tab: 1,

      phoneNumber: "",
      verificationCode: "",
      countryCode: "",
      country: "",
      countryObject: {},
      deliveryModeId: null,
      ErrorMgs: "",
      editData: {
        
      }
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.gethealth_hub_req_data
        ? this.gethealth_hub_req_data?.item1[0]?.totalRecords
        : 0;
    },

    totalRecords() {
      return this.gethealth_hub_req_data
        ? this.gethealth_hub_req_data?.item2[0]?.totalRecords
        : 0;
    },
    ...mapGetters("dashboard", ["all_countries"]),
    ...mapGetters("healthhub", [
      "gethealth_hub_req_data",
      "category",
      "sub_category"
    ]),
    typeOneOnly: function() {
      return this.gethealth_hub_req_data && this.gethealth_hub_req_data.item5
        ? this.gethealth_hub_req_data.item5.filter((data) => data.typeId == 1)
        : 0;
    }
  },
  watch: {
    SuperCategoryId(newVal, oldValue) {
      if (newVal) {
        this.result = "";
        this.editData.description = "";
        this.editData.serviceFAQ = "";
        this.catId = "";
        this.subCategoryId = "";

        this.$store.dispatch("healthhub/getHealthhubSubCategory", 0);
      }
    }
  },
  mounted() {
    this.getPaginatedData();
    this.getAllHealthPacks();
    // Set the initial number of items
    

    this.getClientPurchasePaginatedData();
    this.totalRows = this.items.length;
    this.getCountry();
    // this.searchHelthHub();
  },
  methods: {
    changeTab(data) {
      this.activeTab = data;
      this.tab = data;
      if(this.tab == 1){
        this.getAllHealthPacks()
      }else{
        this.getClientPurchasePaginatedData()
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllHealthPacks() {
      this.loading = true;
      this.$store
        .dispatch("healthhub/GetHealthHubRequiredData")
        .then((response) => {
          this.loading = false;
          this.tableData = response.item1;
          console.log(this.tableData)
          this.supercategory = response.item3;
          this.clientpack = response.item2;
        });
    },
    searchHelthHub() {
      this.$store
        .dispatch("healthhub/searchHelthHub", this.filter)
        .then((response) => {
          this.tableData = response.data;
        });
    },

    searchClientPurchase() {
      this.$store
        .dispatch("healthhub/searchClientPurchase", this.filterclient)
        .then((response) => {
          this.tableData = response.data;
        });
    },

    openVerifyPackModal(data) {
      this.phoneNumber = data.beneficiaryPhoneNumber
    
      $("#exampleModalCenter").modal("show");
    },

    healthHubMerchantverifyClientPurchase() {
      // let newPhone = this.phoneNumber;
      // if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
      //   var phone = newPhone.substring(1);
      // }
      // let main_number = `${this.countryCode}${phone}`;

      const payload = {
        verificationCode: this.verificationCode,
        phoneNumber: this.phoneNumber
      };

      this.loading = true;
      this.$store
        .dispatch("healthhub/verifyClientPurchase", payload)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            location.reload();

            $("#exampleModalCenter").modal("hide");
            this.$toasted.success("Client Purchase verified", {
              duration: 5000
            });
            this.tab = 2
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toasted.error(err.response.data, { duration: 5000 });
        });
    },

    updateDesc(e) {
      this.$store.dispatch("healthhub/updateDesc", e.target.value);
    },
    updateServiceFAQ(e) {
      this.$store.dispatch("healthhub/updateServiceFAQ", e.target.value);
    },
    SetSuperCategory(e) {
      this.SuperCategoryId = e.target.value;
      this.$store.dispatch("healthhub/getHealthHubCategory", e.target.value);
    },

    SetCategory(e) {
      this.categoryId = e.target.value;
      this.$store.dispatch("healthhub/getHealthhubSubCategory", e.target.value);
    },

    onCheckboxChange(e) {
      // console.log(e.target.value);
      this.editData.IsActive = e.target.value;
    },

    SetSubCategory(e) {
      this.SubCategoryId = e.target.value;
      this.editData.SubCategoryId = e.target.value;
      const response = this.sub_category.find((el) => el.id == e.target.value);
      this.result = response;
      // this.editData.serviceFAQ = this.result.serviceFAQ;
      // this.editData.description = this.result.description;
    },

    CreateHealthPackage() {
      if (!this.fullName || !this.DeliveryModeId) {
        this.ErrorMgs = "This field is required";

        return false;
      }

      const payload = {
        fullName: this.fullName,
        description:
          this.result.description || this.$store.state.healthhub.desc,
        servicefaq:
          this.result.serviceFAQ || this.$store.state.healthhub.services_faq,

        SubCategoryId: this.SubCategoryId,
        EffectiveStartDate: this.EffectiveStartDate,
        EffectiveEndDate: this.EffectiveEndDate,
        DeliveryModeId: this.DeliveryModeId,
        PackageQuantity: this.PackageQuantity,
        PackageQuantityUnitId: this.PackageQuantityUnitId,
        ExpirationQuantity: this.ExpirationQuantity,
        ExpirationQuantityUnitId: this.ExpirationQuantityUnitId,
        Cost: this.Cost,
        IsActive: this.IsActive
      };

      // console.log(payload);
      this.loadingAction = true;
      this.$store
        .dispatch("healthhub/healthhubMerchantCreate", payload)
        .then((response) => {
          if (response) {
            this.loadingAction = false;
            this.$swal({
              icon: "success",
              title: "succes",
              text: "Health Package Created Successful"
            });

            this.getAllHealthPacks();
            (this.fullName = ""),
              this.$store.dispatch("healthhub/getHealthhubSubCategory", 0);
            this.$store.dispatch("healthhub/getHealthhubSubCategory", 0);
            (this.deliveryModeId = ""), (this.result = "");
            (this.PackageQuantity = ""),
              (this.PackageQuantityUnitId = ""),
              (this.ExpirationQuantity = ""),
              (this.ExpirationQuantityUnitId = ""),
              (this.Cost = "");
            window.location.reload();
          }
        })
        .catch((err) => {
          this.loadingAction = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },
    //UpdateHealthPackage

    updateHealthPack() {
      this.loadingAction = true;
      this.$store
        .dispatch("healthhub/UpdateHealthPackage", this.editData)
        .then((response) => {
          if (response) {
            this.loadingAction = false;
            this.$swal({
              icon: "success",
              title: "succes",
              text: "Health Package Updated Successful"
            });

            this.getAllHealthPacks();

            // window.location.reload();
          }
        })
        .catch((err) => {
          this.loadingAction = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },

    getData(id) {
      const res = this.tableData.find((data) => data.id == id);
      setTimeout(() => {
        this.$store.dispatch("healthhub/getHealthHubCategory", this.superCatId);
        this.$store.dispatch("healthhub/getHealthhubSubCategory", this.catId);
      }, 500);

      this.deliveryModeId = res.deliveryModeId;
      this.superCatId = res.superCategoryId;
      this.subCatId = res.subCategoryId;
      this.catId = res.categoryId;
      this.subCategoryId = res.subCategoryId;
      this.editData.SubCategoryId = res.subCategoryId;
      this.editData.serviceFAQ = res.serviceFAQ;
      this.editData.fullName = res.fullName;
      this.editData.description = res.description;
      this.editData.EffectiveEndDate = res.effectiveEndDate;
      this.editData.EffectiveStartDate = res.effectiveStartDate;
      this.editData.category = res.category;
      this.editData.IsActive = res.isActive;
      this.editData.categoryId = res.categoryId;
      this.editData.deliveryMode = res.deliveryMode;
      (this.editData.DeliveryModeId = res.deliveryModeId),
        (this.editData.PackageQuantity = res.packageQuantity);
      this.editData.ExpirationQuantity = res.expirationQuantity;
      this.editData.ExpirationQuantityUnitId = res.expirationQuantityUnitId;
      this.editData.ExpirationQuantityUnit = res.expirationQuantityUnit;
      this.editData.Cost = res.cost;
      this.editData.PackageQuantityUnit = res.packageQuantityUnit;
      (this.editData.PackageQuantityUnitId = res.packageQuantityUnitId),
        (this.editData.guid = res.guid);
      this.editData.timeStamp = res.timeStamp;
    },

    getCountry: function() {
      this.$store.dispatch("dashboard/GetAllCountries");
    },
    updateCountry(e) {
      this.countryCode = e.target.value;
    },
    UpdatecategoryId(e) {
      this.editData.categoryId = e.target.value;
    },
    UpdateDeliveryModeId(e) {
      this.editData.DeliveryModeId = e.target.value;
    },
    UpdatePackageQuantityUnitId(e) {
      this.editData.PackageQuantityUnitId = e.target.value;
    },
    UpdateExpirationQuantityUnitId(e) {
      this.editData.ExpirationQuantityUnitId = e.target.value;
    },

    updateList() {
      const data = this.gethealth_hub_req_data.item1.filter(
        (data) => data.superCategoryId == this.selectedID
      );
      this.filterData = data;
    },

    updatepackageCategoryId() {
      const data = this.gethealth_hub_req_data.item2.filter(
        (data) => data.packageSuperCategoryId == this.selectedpackageCategoryId
      );
      this.packageCategory = data;
    },

    getPaginatedData() {
      const data = {
        pageNumber: this.currentPage,
        searchTerm: this.searchterm
      };
      this.$store
        .dispatch("healthhub/getHealthHubPagination", data)
        .then((response) => {
          this.tableData = response;
        });
    },

    getClientPurchasePaginatedData() {
      const data = {
        pageNumber: this.currentPage,
        searchTerm: this.searchterm
      };
      this.$store
        .dispatch("healthhub/getClientPurchasePaginatedData", data)
        .then((response) => {
          this.clientpack = response;
          // console.log("==>", this.clientpack);
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <nav class="nav">
      <a
        class="nav-link btn btn-outline-warning mr-3"
        @click="changeTab(1)"
        href="#"
        :class="{ active: activeTab === 1 }"
        >Health Hub Packages</a
      >
      <a
        class="nav-link btn btn-outline-primary"
        @click="changeTab(2)"
        href="#"
        :class="{ active: activeTab === 2 }"
        >Client Purchased Packs</a
      >
    </nav>

    <div class="row" v-if="tab == 1">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-md-12 d-flex justify-content-end">
                <button
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                  class="btn btn-sm mb-5 btn-success"
                >
                  Add Health Package
                </button>
              </div>

              <!-- New health pack -->
              <div
                class="modal fade bd-example-modal-lg"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="healthhubModalLabel">
                        New Health Hub Package
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="row">
                          <div class="col">
                            <div class="form-group" v-if="supercategory">
                              <label for="category"
                                >Select Super Category</label
                              >

                              <select
                                @change="SetSuperCategory"
                                name=""
                                id=""
                                class="form-control"
                              >
                                <option value="0" selected
                                  >select super category</option
                                >
                                <option
                                  v-for="cat in supercategory"
                                  :key="cat.id"
                                  :value="cat.id"
                                  >{{ cat.fullName }}</option
                                >
                              </select>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="delevery mode">Select Category</label>
                              <select
                                @change="SetCategory"
                                name=""
                                id=""
                                class="form-control"
                              >
                                <option value="" selected
                                  >select category</option
                                >
                                <option
                                  v-for="subcat in category"
                                  :key="subcat.id"
                                  :value="subcat.id"
                                  >{{ subcat.fullName }}</option
                                >
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="category">Select Sub Category</label>
                              <select
                                @change="SetSubCategory"
                                name=""
                                id=""
                                class="form-control"
                              >
                                <option value="" selected
                                  >select sub category</option
                                >
                                <option
                                  v-for="cat in sub_category"
                                  :key="cat.id"
                                  :value="cat.id"
                                  >{{ cat.fullName }}</option
                                >
                              </select>
                            </div>
                          </div>
                          <div class="col">
                            <div
                              class="form-group"
                              v-if="
                                gethealth_hub_req_data &&
                                  gethealth_hub_req_data.item4
                              "
                            >
                              <label for="delevery mode">Delivery Mode</label>
                              <select
                                v-model="DeliveryModeId"
                                name=""
                                id=""
                                class="form-control"
                              >
                                <option
                                  v-for="dvlmode in gethealth_hub_req_data.item4"
                                  :key="dvlmode.id"
                                  :value="dvlmode.id"
                                  >{{ dvlmode.fullName }}</option
                                >
                              </select>
                            </div>
                            <small class="text-danger" v-if="ErrorMgs">{{
                              ErrorMgs
                            }}</small>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="fullname">Full Name</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Full Name"
                            v-model="fullName"
                          />
                          <small class="text-danger" v-if="ErrorMgs">{{
                            ErrorMgs
                          }}</small>
                        </div>

                        <div class="form-group">
                          <label for="desc">Description</label>
                          <textarea
                            name="desc"
                            class="form-control"
                            rows="6"
                            :value="result ? result.description : ''"
                            @change="updateDesc"
                          ></textarea>
                        </div>

                        <div class="form-group">
                          <label for="desc">Service FAQ</label>
                          <textarea
                            name="desc"
                            class="form-control"
                            rows="6"
                            :value="result ? result.serviceFAQ : ''"
                            @change="updateServiceFAQ"
                          ></textarea>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="package quantity"
                                >Package Quantity</label
                              >
                              <input
                                v-model="PackageQuantity"
                                type="number"
                                class="form-control"
                                name=""
                                id=""
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div
                              class="form-group"
                              v-if="
                                gethealth_hub_req_data &&
                                  gethealth_hub_req_data.item5
                              "
                            >
                              <label for="package quantity">Package Unit</label>
                              <select
                                v-model="PackageQuantityUnitId"
                                name=""
                                id=""
                                class="form-control"
                              >
                                <option
                                  v-for="expqty in gethealth_hub_req_data.item5"
                                  :key="expqty.id"
                                  :value="expqty.id"
                                  >{{ expqty.fullName }}</option
                                >
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="package quantity"
                                >Expiration Quantity</label
                              >
                              <input
                                type="number"
                                class="form-control"
                                placeholder="expiration quantity"
                                v-model="ExpirationQuantity"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div
                              class="form-group"
                              v-if="
                                gethealth_hub_req_data &&
                                  gethealth_hub_req_data.item4
                              "
                            >
                              <label for="package quantity unit"
                                >Expiration Unit</label
                              >
                              <select
                                v-model="ExpirationQuantityUnitId"
                                name=""
                                id=""
                                class="form-control"
                              >
                                <option
                                  v-for="expqty in typeOneOnly"
                                  :key="expqty.id"
                                  :value="expqty.id"
                                  >{{ expqty.fullName }}</option
                                >
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="package quantity">Start Date</label>
                              <datetime
                                format="YYYY-MM-DD H:i:s"
                                width="100px"
                                v-model="EffectiveStartDate"
                              ></datetime>
                              <!-- <input
                                type="date"
                                class="form-control"
                                placeholder="expiration quantity"
                                v-model="EffectiveStartDate"
                              /> -->
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="package quantity unit"
                                >End Date</label
                              >
                              <datetime
                                format="YYYY-MM-DD H:i:s"
                                width="100px"
                                v-model="EffectiveEndDate"
                              ></datetime>
                              <!-- <input
                                type="date"
                                class="form-control"
                                placeholder="expiration quantity"
                                v-model="EffectiveEndDate"
                              /> -->
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="package quantity">Cost</label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="cost"
                                v-model="Cost"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-check pt-5">
                              <label class="form-check-label›" for="check2">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="option2"
                                  v-model="IsActive"
                                  value="something"
                                />Is Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        @click.prevent="CreateHealthPackage"
                        type="button"
                        class="btn btn-primary"
                      >
                        <b-spinner v-if="loadingAction" type="grow"></b-spinner>
                        <span v-else>Save Health Package</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12  col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select
                      class="form-control form-control-sm form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6  d-flex justify-content-end">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      @keyup.enter="searchHelthHub"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
<!-- TODO: Revisite -->
            <div class="row">
              <b-table :items="tableData" :fields="fields">
                <template v-slot:cell(Action)="row">
                  <button
                    @click="getData(row.item.id)"
                    type="button"
                    data-toggle="modal"
                    data-target="#EditModal"
                    class="btn btn-success"
                  >
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i>
                  </button>

                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="EditModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="EditModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-lg text-black"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="EditModalLabel">
                            Edit Health Hub Package
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <form>
                            <div class="row">
                              <div class="col">
                                <div
                                  class="form-group"
                                  v-if="
                                    gethealth_hub_req_data &&
                                      gethealth_hub_req_data.item3
                                  "
                                >
                                  <label for="category"
                                    >Select Super Category</label
                                  >

                                  <select
                                    @change="SetSuperCategory"
                                    name=""
                                    id=""
                                    class="form-control"
                                  >
                                    <option value="0"
                                      >select super category</option
                                    >
                                    <option
                                      v-for="cat in gethealth_hub_req_data.item3"
                                      :key="cat.id"
                                      :value="cat.id"
                                      :selected="cat.id == superCatId"
                                      >{{ cat.fullName }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="delevery mode"
                                    >Select Category</label
                                  >
                                  <select
                                    @change="SetCategory"
                                    name=""
                                    id=""
                                    class="form-control"
                                  >
                                    <option value="0">select category</option>
                                    <option
                                      v-for="subcat in category"
                                      :key="subcat.id"
                                      :value="subcat.id"
                                      :selected="subcat.id == catId"
                                      >{{ subcat.fullName }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="category"
                                    >Select Sub Category</label
                                  >
                                  <select
                                    @change="SetSubCategory"
                                    name=""
                                    id=""
                                    class="form-control"
                                  >
                                    <option value=""
                                      >select sub category</option
                                    >
                                    <option
                                      v-for="cat in sub_category"
                                      :key="cat.id"
                                      :value="cat.id"
                                      :selected="cat.id == subCatId"
                                      >{{ cat.fullName }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="form-group"
                                  v-if="
                                    gethealth_hub_req_data &&
                                      gethealth_hub_req_data.item4
                                  "
                                >
                                  <label for="delevery mode"
                                    >Delivery Mode</label
                                  >
                                  <select
                                    @change="UpdateDeliveryModeId"
                                    name=""
                                    id=""
                                    class="form-control"
                                  >
                                    <!-- <option selected>{{
                                      editData.deliveryMode
                                    }}</option> -->
                                    <option
                                      v-for="dvlmode in gethealth_hub_req_data.item4"
                                      :key="dvlmode.id"
                                      :value="dvlmode.id"
                                      :selected="dvlmode.id == deliveryModeId"
                                      >{{ dvlmode.fullName }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                              <label for="fullname">Full Name</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Full Name"
                                v-model="editData.fullName"
                              />
                            </div>

                            <div class="form-group">
                              <label for="desc">Description</label>
                              <textarea
                                name="desc"
                                class="form-control"
                                rows="7"
                                v-model="editData.description"
                              ></textarea>
                            </div>

                            <div class="form-group">
                              <label for="desc">Service FAQ</label>
                              <textarea
                                name="desc"
                                class="form-control"
                                rows="7"
                                :value="editData.serviceFAQ"
                              ></textarea>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="package quantity"
                                    >Package Quantity</label
                                  >
                                  <input
                                    v-model="editData.PackageQuantity"
                                    type="number"
                                    class="form-control"
                                    name=""
                                    id=""
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="package quantity"
                                    >Package Unit</label
                                  >
                                  <select
                                    @change="UpdatePackageQuantityUnitId"
                                    name=""
                                    id=""
                                    class="form-control"
                                  >
                                    <option selected>{{
                                      editData.PackageQuantityUnit
                                    }}</option>
                                    <option
                                      v-for="expqty in typeOneOnly"
                                      :key="expqty.id"
                                      :value="expqty.id"
                                      >{{ expqty.fullName }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="package quantity"
                                    >Expiration Quantity</label
                                  >
                                  <input
                                    type="number"
                                    class="form-control"
                                    placeholder="expiration quantity"
                                    v-model="editData.ExpirationQuantity"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div
                                  class="form-group"
                                  v-if="
                                    gethealth_hub_req_data &&
                                      gethealth_hub_req_data.item4
                                  "
                                >
                                  <label for="package quantity unit"
                                    >Expiration Unit</label
                                  >
                                  <select
                                    @change="UpdateExpirationQuantityUnitId"
                                    name=""
                                    id=""
                                    class="form-control"
                                  >
                                    <option selected>{{
                                      editData.ExpirationQuantityUnit
                                    }}</option>
                                    <option
                                      v-for="expqty in gethealth_hub_req_data.item5"
                                      :key="expqty.id"
                                      :value="expqty.id"
                                      >{{ expqty.fullName }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="package quantity"
                                    >Start Date</label
                                  >
                                  <input
                                    type="datetime-local"
                                    class="form-control"
                                    placeholder="expiration quantity"
                                    v-model="editData.EffectiveStartDate"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="package quantity unit"
                                    >End Date</label
                                  >
                                  <input
                                    type="datetime-local"
                                    class="form-control"
                                    placeholder="expiration quantity"
                                    v-model="editData.EffectiveEndDate"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="package quantity">Cost</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    placeholder="cost"
                                    v-model="editData.Cost"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-check pt-5">
                                  <label class="form-check-label" for="check2">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      :value="
                                        editData.IsActive == true ? false : true
                                      "
                                      :checked="editData.IsActive"
                                      @change="onCheckboxChange"
                                    />Is Active
                                  </label>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            @click.prevent="updateHealthPack"
                            type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                          >
                            update Health Package
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!-- <b-spinner
                v-if="loading"
                style="width: 3rem; height: 3rem;"
                label="Large Spinner"
              ></b-spinner> -->
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul
                    @click="getPaginatedData"
                    class="pagination pagination-rounded mb-0"
                  >
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="tab == 2">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-md-12 d-flex justify-content-end">
                <button
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  class="btn btn-sm mb-5 btn-success"
                >
                  Verify Package Purchase
                </button>
              </div>

              <!-- Modal -->
              <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Verify Package Purchase
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <!-- <div class="form-group">
                          <label for="countries"
                            >Select your country Code</label
                          >
                          <select
                            class="form-control"
                            @change="updateCountry"
                            v-model="countryObject"
                            name="countries"
                          >
                            <option
                              v-for="cty in all_countries"
                              :key="cty.id"
                              :value="cty.callCode"
                              >{{ cty.callCode }}</option
                            >
                          </select>
                        </div> -->

                        <!-- <div class="form-group">
                          <label for="client phone">Client Phone</label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="client phone"
                            v-model="phoneNumber"
                          />
                        </div> -->

                        <div class="form-group">
                          <label for="client phone">Verify Code</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="enter verification code"
                            v-model="verificationCode"
                          />
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        @click.prevent="healthHubMerchantverifyClientPurchase"
                        type="button"
                        class="btn btn-primary"
                      >
                        <b-spinner v-if="loading" type="grow"></b-spinner>
                        <span v-else> Verify Purchase</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12  col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select
                      class="form-control form-control-sm form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6  d-flex justify-content-end">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterclient"
                      @keyup.enter="searchClientPurchase"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <!-- <div class="table-responsive mb-0 datatables">
              <b-table
                :items="clientpack"
                :fields="clientfields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              </b-table>
            </div> -->

            <b-table :items="clientpack" :fields="clientfields">
              <template v-slot:cell(packageState)="row">
                <span class="badge  badge-pill badge-info">{{
                  row.item.packageStatus
                }}</span>
              </template>
              <template v-slot:cell(Action)="row">
                <button 
                @click="openVerifyPackModal(row.item)" 
                v-if="row.item.packageStatus == 'New'"
                 type="button"
                  class="btn btn-success">
                  <i class="fas fa-check-circle" aria-hidden="true" title="Edit"></i>
                </button>
              </template>
            </b-table>

            <b-spinner
              v-if="loading"
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
            ></b-spinner>

            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul
                    @click="getClientPurchasePaginatedData"
                    class="pagination pagination-rounded mb-0"
                  >
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecords"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.btn-warning {
  background: #f15825 !important;
}
</style>
